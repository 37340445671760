html,
body,
#root {
	background: #fff;
	padding: 0;
	margin: 0;
}

* {
	font-family: 'Outfit', sans-serif;
}

*,
:after,
:before {
	box-sizing: border-box;
	user-select: none;
}

input,
textarea {
	user-select: auto;
}

@-ms-viewport {
	width: device-width;
}